import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/frontend.svg";

const FrontendUserExperience = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-frontend.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      fabio: file(relativePath: { eq: "profiles/fabio-baser.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.frontend.link">
      <Seo
        title="Frontend und User Experience – Monday Consulting"
        description="Für uns ist die Frontend-Entwicklung eine moderne Handwerkskunst. Deswegen entwickeln wir Webseiten, die nicht nur schön, sondern vor allem auch funktional, schnell und ausfallsicher sind."
        keywords="Frontend, UX, User Experience, Headless, Performance, TypeScript, Lighthouse, Barrierefreiheit"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Jede Seite ein Meisterstück"
        subline="Unser Frontend-Team entwickelt höchste Qualität, die sich sehen lassen kann."
        section="Frontend & User Experience"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Frontend & User Experience"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Nicht nur schön, sondern auch funktional"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Nicht nur schön, sondern auch funktional</h2>
            <p>
              Der erste Eindruck Ihres potentiellen Neukunden entsteht nicht nur
              durch ein gelungenes Webdesign, sondern bereits vorher bei der
              Suchmaschinenplatzierung und der Performance. Deswegen entwickeln
              wir Webseiten, die nicht nur schön, sondern vor allem auch
              funktional, schnell und ausfallsicher sind. Für uns ist die
              Frontend-Entwicklung eine moderne Handwerkskunst: Keine Seite
              gleicht der anderen, aber jede zeugt von größter Qualität.
              Modernste Tools bieten uns bei der Entwicklung viele Vorteile, wie
              eine kürzere Time-to-Market, maximale Ausfallsicherheit und eine
              bessere Performance, die sich auf Ihre SEO auswirkt. Aber auch mit
              altbewährten Technologien kennen wir uns bestens aus und nutzen
              immer die, die für Sie den größten Mehrwert bieten.
            </p>
            <p>
              Egal ob Neuanfang oder bestehendes Projekt: Wir scheuen keine
              Herausforderung und setzen stets auf maximale Kundenzufriedenheit
              – für Sie und für uns. Der Endnutzer ist immer im Fokus.
              Hervorragende User Experience (UX), zukunftsorientierte
              Barrierefreiheit und eine Top Performance stehen für uns außer
              Frage.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Frontend & User Experience im Detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-technology bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Moderne Technologien
            </h3>
            <p>
              Wir setzen auf modernste Programmiersprachen und Frameworks, um
              eine erhöhte Entwicklungsgeschwindigkeit und bessere Qualität zu
              gewährleisten. So nutzen wir in vielen Projekten bereits
              TypeScript, React, Tailwind CSS oder ähnliche smarte Technologien,
              die Ihr Projekt schnell nach vorne bringen.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-headless bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Headless-Architektur
            </h3>
            <p>
              Durch die Trennung von Inhalt und Darstellung wird Content-Pflege
              einfacher und die Gestaltung flexibler. Der Umsetzung Ihres
              Wunsch-Designs sind kaum Grenzen gesetzt, da wir dank moderner
              Frameworks mehr Freiheiten und Möglichkeiten haben.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-performance bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Performance = SEO
            </h3>
            <p>
              Wir entwickeln performante Webseiten, die einen hohen Lighthouse
              Score garantieren. Das wirkt sich nicht nur positiv auf die
              Kundenzufriedenheit aus, sondern auch auf Ihr
              Suchmaschinen-Ranking.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-a11y bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Barrierefreiheit
            </h3>
            <p>
              Für uns ist Barrierefreiheit selbstverständlich – nicht nur
              gesetzlich notwendig, sondern ein grundsätzliches Zeichen von
              Qualität. Unsere Webseiten sind barrierefrei und bieten deshalb
              allen Nutzern eine hervorragende User Experience.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="lebensbaum,generali" />
      </div>

      <ContactPerson
        headline="Haben wir Ihr Interesse geweckt?"
        text="Sie stehen in Ihrem Unternehmen vor einer ähnlichen Herausforderung? Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit. Unsere Experten beraten Sie gerne."
        contactEmail="frontend@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Fabio Baser, Unit-Leiter Frontend"
      >
        <GatsbyImage
          image={images.fabio.childImageSharp.gatsbyImageData}
          alt="Fabio Baser"
        />
      </ContactPerson>
    </Layout>
  );
};

export default FrontendUserExperience;
